/* autor a link na 3 clanky pod clankem na blesk.cz */

.autorLeft {
	background: #fff;
	margin: 5px 0 5px -80px;
	padding: 10px;
	width: 600px;
	overflow: hidden;
	/*position: relative;*/
	border-top:3px solid #f00;
}

.autorLeft .name {
	display: block;
	/*background: #ffffff;*/
	border-bottom: 1px solid #cad5df;
	font-family: 'tablet_gothic_condensed', 'Arial';
	font-size: 16px;
	text-decoration: none;
	float:left;
	margin:0 10px 15px 20px;
	width: 470px;
	padding-bottom: 5px;
	font-weight: bold;
	text-transform: uppercase;
}

.autorLeft .name a {
	color: #FF0036;
	text-decoration: none;
	font-weight: normal;
}
.autorLeft .anotImg {
	margin: 0;
	float: left;
	width: 90px;
	height: 140px;
	display: block;
	overflow: hidden;
	border: none;
}

.autorLeft .anotImg img {
	width: 90px;
}

.autorLeft .clankyAutora {
	display: block;
	float: left;
	width: 470px;
	font-size: 15px;
	overflow: hidden;
	margin: 0 0 0 20px;
	font-weight: bold;
	font-family: 'Arial';
}
.autorLeft .clankyAutora .leftArt {
	padding:  0;
}

.autorLeft .clankyAutora a,
.autorLeft .anotDetail a {
	text-decoration: none;
	color:#16212d;
}
.autorLeft .clankyAutora a:hover,
.autorLeft .anotDetail a:hover {
	text-decoration: underline;
	color: #FF0036;
}


.profilAuth {
	float:right;
}
.autorLeft .name .profilAuth a {
	color:#16212d;
	display:inline-block;

}

.profilAuth span.sipka,.clankyAutora .leftArt span {
	width: 8px;
	height: 10px;
	background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -250px -216px transparent;
	display: inline-block;
	margin-left:15px;
}

/*profil autora detail - uses something from prev section as well */
.autorLeft.autoriVypis {
	background: transparent;
	overflow: hidden;
	border-top-width:4px;
	padding:0px;
	margin-left:0;
	width:620px;
	padding-top: 10px;
	margin-bottom: 0;

}
.autorLeft.autoriVypis .desc  {
	width:500px;
	display: block;
	float: left;
	font-size: 15px;
	overflow: hidden;
	margin: 0 0 0 20px;
	height: 80px;
}
.autorLeft.autoriVypis .desc p {
	font-family: 'Arial';
	font-weight:normal;
	line-height: 20px;
}
.autorLeft.autoriVypis .name {
	border:none;
	font-size:24px;
	margin-bottom:0;
	width:510px;
	padding-right:0;
	margin-right: 0;
}
.autorLeft.autoriVypis .shadowAuth {
	margin-top: -9px;
	margin-left:90px;
	width:500px;
	height:20px;
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -350px -950px  no-repeat;
}
.autorLeft .jmenoAuth {
	float:left;
}

.social-container-bottom .autorLeft {
	margin: 5px 0 5px 0;
}

.autoriVypis .twitIco {
	display: inline-block;
	width: 29px;
	height: 30px;
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -550px -1300px no-repeat;
}
.autoriVypis .fbIco {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -600px -1300px no-repeat;
}
.autoriVypis .mailIco {
	background: url("https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1") -650px -1300px no-repeat;
}
/*    clanky autora  */
h2.headAuthArt {
	font-family: 'tablet_gothic_condensed','Arial';
	font-size: 20px;
	font-weight: bold;
	padding:0;
	margin:0 0 0 10px;
}
/* Select autoru*/

.placingSelect {
	position: absolute;
	top: 150px;
	left: 380px;
	z-index: 10;
	width: 250px;
	text-align: right;
}
.selA h2 {
	color:#f00;
	display: inline-block;
	margin: 0px 0px 0px 115px;
	padding: 0;
}

.selA .arrowDown {
	width:10px;
	height: 8px;
	display: inline-block;
	background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -250px -200px transparent;
	margin-left: 10px;
	margin-bottom: 3px;
}

.arrowDown.turnedDown {
	background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -250px -200px transparent;
}
.arrowDown.turnedUp {
	background: url('https://img2.cncenter.cz/images/blesk-2013/blesk-sprites-webp.webp?v=1') no-repeat scroll -250px -200px transparent;
	-moz-transform:rotate(180deg);
	-webkit-transform:rotate(180deg);
		transform:rotate(180deg);
}

.otherAuth {
	top: 9px;
	left: 0px;
	position: relative;
	display: none;
	background: none repeat scroll 0% 0% #F00;
	color: #FFF;
	font-family: 'tablet_gothic_condensed','Arial';
	font-size: 24px;
}

.otherAuth .wrappAuth {
	border-bottom:1px solid #fff;
}
.otherAuth .photoAuthSmall {
	float:left;
	padding:7px 0;
}
.otherAuth .photoAuthSmall img {
	float: left;
	width:26px;
	margin-left: 7px;
}

.otherAuth .jmenoAuthRight {
	float: right;
	text-align: left;
	margin-top: 15px;
	width: 200px;
	margin-left: 10px;
}
.otherAuth .jmenoAuthRight a {
	color:#fff;
	text-decoration:none;
}
.otherAuth .jmenoAuthRight a:hover {
	text-decoration:underline;
}
